import styled from "styled-components";

export const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const device = {
  mobileS: `(min-width: ${size.mobileS})`,
  mobileM: `(min-width: ${size.mobileM})`,
  mobileL: `(min-width: ${size.mobileL})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktop})`,
};

export const colors = {
  black: "#0d0d0d",
  lightGray: "#ababab",
  lightGrayHover: "#dedede",
  white: "#fff",
  red: "#f56161",
  purple: "#7e53ac",
  orange: "#ff932e",
  mediumGray: "#121212",
};

export const spacing = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

export const theme = {
  breakpoints: {
    maxWidth: "1920px",
    ...device,
  },
  gradient: {
    default: `linear-gradient(258deg, ${colors.purple}, ${colors.red} 100%)`,
    inverseDefault: `linear-gradient(258deg, ${colors.red}, ${colors.purple} 100%)`,
  },
  colors: {
    ...colors,
  },
  spacing: {
    ...spacing,
  },
};

export const Container = styled.section`
  max-width: ${theme.breakpoints.maxWidth};
  display: flex;
  flex-wrap: wrap;
  padding: 72px 18px;
  width: calc(100% - 36px);
  margin: 0 auto;
  position: relative;

  @media ${theme.breakpoints.laptop} {
    padding: 128px 84px;
    width: calc(100% - 168px);
  }
  &:before {
    content: "";
    pointer-events: none;
    position: absolute;
    z-index: -1;
    width: 100vw;
    top: 0;
    height: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    background-color: inherit;
  }
`;

export const SectionHeader = styled.h2`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin: 0 auto;
  width: 100%;

  @media ${theme.breakpoints.tablet} {
    font-size: 62px;
  }
`;

export const NavWrapper = styled.div`
  width: calc(100% - 36px);
  max-width: ${theme.breakpoints.maxWidth};
  margin: 0 auto;
  display: flex;

  @media ${theme.breakpoints.laptop} {
    width: calc(100% - 80px);
  }
`;
