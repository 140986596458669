import React, { useState } from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import styled, { ThemeProvider } from "styled-components";
import "normalize.css";
import { theme } from "../../constants/theming";
import Nav from "./Nav";
import Footer from "./Footer";
import PageContainer from "./PageContainer";
import ReferralContext, { ReferralUUID } from "../../context";

const uuid = ((Math.random() * 0xffffff) << 0).toString(16);

const Main = styled.main`
  overflow: hidden;
`;

const Layout = ({ children, location }) => {
  const [showReferral, setShowReferral] = useState(false);

  return (
    <StaticQuery
      query={graphql`
        query LayoutQuery {
          contentfulSiteSiteContainer(siteTitle: { eq: "Wavvve" }) {
            metaData {
              metaDescription
              pageTitle
              ogTwitterTitle
              ogTwitterDescription
              ogImage {
                file {
                  url
                }
              }
              twitterImage {
                file {
                  url
                }
              }
            }
            siteNav {
              logo {
                altText
                asset {
                  file {
                    url
                  }
                }
              }
              siteLinks {
                link
                copy
              }
              optionalAdditionalMenu {
                blocks {
                  ... on ContentfulComponentEmailForm {
                    emailDescription {
                      header {
                        json
                      }
                      paragraph {
                        json
                      }
                    }
                    emailInput {
                      placeholderCopy
                    }
                    submitButtonCopy
                  }
                }
              }
            }
            footer {
              logo {
                file {
                  url
                }
              }
              links {
                link
                copy
              }
              copyright
            }
          }
        }
      `}
      render={(data) => {
        const { metaData, siteNav, footer } = data.contentfulSiteSiteContainer;

        return (
          <ThemeProvider theme={theme}>
            <Helmet
              htmlAttributes={{
                lang: "en",
              }}
            >
              <meta name="robots" content="noindex" />
              <title>{metaData.pageTitle}</title>
              <meta name="description" content={metaData.metaDescription} />
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
              />
              <meta property="og:title" content={metaData.ogTwitterTitle} />
              <meta property="og:site_name" content={process.env.SITE_URL} />
              <meta property="og:url" content={process.env.SITE_URL} />
              <meta
                property="og:description"
                content={metaData.ogTwitterDescription}
              />
              <meta property="og:type" content="website" />
              <meta
                property="og:image"
                content={`https:${metaData.ogImage.file.url}`}
              />
              <meta
                property="twitter:image"
                content={`https:${metaData.twitterImage.file.url}`}
              />
            </Helmet>
            <ReferralUUID.Provider value={{ uuid }}>
              <ReferralContext.Provider
                value={{ showReferral, setShowReferral }}
              >
                <PageContainer>
                  <Nav data={siteNav} location={location} />
                  <Main>{children}</Main>
                  <Footer data={footer} />
                </PageContainer>
              </ReferralContext.Provider>
            </ReferralUUID.Provider>
          </ThemeProvider>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
