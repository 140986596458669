import { size, device } from "./theming";

export const SIGN_UP_FORM_NAME = "contact";

export const SIGN_UP_FORM_INITIAL_STATE = ({ id }) => ({
  email: "",
  referral: id,
  "form-name": SIGN_UP_FORM_NAME,
});

export { size, device };
