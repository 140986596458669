import React from "react";
import styled from "styled-components";
import { Field, Form } from "formik";
import parse from "html-react-parser";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import { theme, NavWrapper } from "../../constants/theming";
import SignUpForm from "../SignUpForm";

const StickyMenu = styled.div`
  background: ${theme.colors.black};
  width: 100%;
  margin: 0 auto;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 56px;
  height: 36px;
  z-index: 5;
`;

const FlexContainer = styled(NavWrapper)`
  justify-content: space-between;
`;

const SignUpFormStyled = styled(SignUpForm)`
  display: flex;
`;

const SignUpText = styled.div`
  color: ${theme.colors.lightGray};
  display: none;
  font-size: 14px;

  @media ${theme.breakpoints.tablet} {
    display: flex;
    align-items: center;
  }
`;

const StyledForm = styled(Form)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  width: 100%;

  @media ${theme.breakpoints.tablet} {
    margin-left: 0;
    width: auto;
  }
`;

const StyledInput = styled(Field)`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: ${theme.colors.white};
  font-size: 13px;
  padding: 0 15px;
  height: 32px;
  border-radius: 4px;
  width: calc(100% - 130px);

  @media ${theme.breakpoints.tablet} {
    width: 200px;
    font-size: 15px;
  }
`;

const GradientButton = styled.button`
  background: ${theme.gradient.default};
  border-radius: 24px;
  outline: none;
  display: flex;
  font-size: 12px;
  align-items: center;
  padding: 0 18px;
  width: auto;
  height: 32px;
  justify-content: center;
  color: ${theme.colors.white};
  border: none;
  margin-left: 16px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  z-index: 1;
  position: relative;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${theme.gradient.inverseDefault};
    z-index: -1;
    transition: opacity 0.4s linear;
    opacity: 0;
  }

  &:hover:before {
    opacity: 1;
  }

  @media ${theme.breakpoints.tablet} {
    font-size: 14px;
  }
`;

const EmailSignUpStickyNav = ({ data, location }) => {
  const { emailDescription, emailInput, submitButtonCopy } = data.blocks[0];

  return (
    <StickyMenu>
      <FlexContainer>
        <SignUpText>
          {parse(documentToPlainTextString(emailDescription.header.json))}
        </SignUpText>
        <SignUpFormStyled formPosition="top" location={location}>
          {({ isSubmitting, formName }) => (
            <StyledForm data-netlify={true} name={formName}>
              <Field type="hidden" name="form-name" />
              <Field type="hidden" name="referral" />
              <Field type="hidden" name="incomingReferral" />
              <StyledInput
                placeholder={emailInput.placeholderCopy}
                type="email"
                name="email"
                required
              />
              <GradientButton type="submit" disabled={isSubmitting}>
                {submitButtonCopy}
              </GradientButton>
            </StyledForm>
          )}
        </SignUpFormStyled>
      </FlexContainer>
    </StickyMenu>
  );
};

export default EmailSignUpStickyNav;
