import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { theme, NavWrapper } from "../../constants/theming";
import EmailSignUpStickyNav from "./EmailSignUpStickyNav";

const NavContainer = styled.nav`
  color: ${theme.colors.lightGray};
  background: ${theme.colors.black};
  height: 56px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 5;
`;

const FlexContainer = styled(NavWrapper)`
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: auto;
  width: 110px;

  @media ${theme.breakpoints.tablet} {
    width: 137px;
  }
`;

const LinksContainer = styled.ul`
  display: none;
  list-style-type: none;
  align-self: flex-end;
  margin: 0;
  align-items: center;
  height: 100%;
  padding: 0;

  @media ${theme.breakpoints.tablet} {
    display: flex;
  }
`;

const StyledLink = styled.li`
  color: ${theme.colors.lightGray};
  margin-right: 32px;
  font-size: 14px;
  cursor: pointer;
  transition: color 150ms linear;

  &:hover {
    color: ${theme.colors.lightGrayHover};
  }

  &:last-of-type {
    margin-right: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
  }
`;

const Nav = ({ data, location }) => {
  const { logo, siteLinks, optionalAdditionalMenu } = data;
  const emailSignUp = optionalAdditionalMenu[0];

  return (
    <>
      <NavContainer>
        <FlexContainer>
          <LogoContainer>
            <a href="/">
              <Logo src={logo.asset.file.url} alt={logo.altText} />
            </a>
          </LogoContainer>
          <LinksContainer>
            {siteLinks.map(({ link, copy }, idx) => (
              <StyledLink key={`menu-list-${idx}`}>
                <Link to={link} smooth={true} offset={-100}>
                  {copy}
                </Link>
              </StyledLink>
            ))}
          </LinksContainer>
        </FlexContainer>
      </NavContainer>
      <EmailSignUpStickyNav data={emailSignUp} location={location} />
    </>
  );
};

export default Nav;
