import React from "react";
import styled from "styled-components";
import { theme, NavWrapper } from "../../constants/theming";

const FooterContainer = styled.footer`
  background: #000;
  width: 100%;
  padding: 15px 0;

  @media ${theme.breakpoints.tablet} {
    padding: 0;
  }
`;

const FlexContainer = styled(NavWrapper)`
  height: auto;
  align-items: center;
  flex-wrap: wrap;

  @media ${theme.breakpoints.tablet} {
    flex-wrap: nowrap;
    height: 88px;
  }
`;

const Logo = styled.img`
  margin-right: 72px;
  width: 137px;
  height: auto;
`;

const Link = styled.a`
  color: ${theme.colors.lightGray};
  text-decoration: none;
  font-size: 14px;
  margin: 12px 0 32px;
  width: 100%;
  transition: color 150ms linear;

  &:hover {
    color: ${theme.colors.lightGrayHover};
  }

  @media ${theme.breakpoints.tablet} {
    margin: 0;
    width: auto;
  }
`;

const Copyright = styled.p`
  color: ${theme.colors.lightGray};
  font-size: 14px;

  @media ${theme.breakpoints.tablet} {
    margin-left: auto;
  }
`;

const Footer = ({ data }) => {
  const { logo, links, copyright } = data;
  return (
    <FooterContainer>
      <FlexContainer>
        <a href="/">
          <Logo
            loading="lazy"
            alt="Wavvve logo"
            src={logo.file.url}
            img={logo.altText}
          />
        </a>
        <>
          {links.map(({ link, copy }, idx) => (
            <Link key={`footer-link-${idx}`} href={link}>
              {copy}
            </Link>
          ))}
        </>
        <Copyright>{copyright}</Copyright>
      </FlexContainer>
    </FooterContainer>
  );
};

export default Footer;
