import queryString from "query-string";

export const queryHandler = ({ location }) =>
  queryString.parse(location.search);

export const validIncomingReferral = ({ incomingReferral }) => {
  if (incomingReferral && incomingReferral !== "") {
    return true;
  }
  return false;
};

export const encode = ({ data }) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};
