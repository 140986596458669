import { trackCustomEvent } from "gatsby-plugin-google-analytics";

export const incomingReferralEvent = ({ incomingReferral }) =>
  trackCustomEvent({
    category: "Traffic Overview",
    action: "Incoming Referral",
    label: `${process.env.SITE_URL} Incoming Referral - ${incomingReferral}`,
  });

export const incomingFacebookReferral = ({ id }) =>
  trackCustomEvent({
    category: "Traffic Overview",
    action: `Facebook Referral`,
    label: `${process.env.SITE_URL} Facebook - ${id}`,
  });

export const incomingLinkedinReferral = ({ id }) =>
  trackCustomEvent({
    category: "Traffic Overview",
    action: `Linkedin Referral`,
    label: `${process.env.SITE_URL} Linkedin - ${id}`,
  });

export const contactFormSubmissionEvent = ({ formPosition }) =>
  trackCustomEvent({
    category: "Contact Form",
    action: `Form Submit - ${formPosition}`,
    label: `${process.env.SITE_URL} Contact Form Submission`,
  });

export const contactFormSubmissionReferralEvent = () =>
  trackCustomEvent({
    category: "Contact Form",
    action: "Form Submit Referral",
    label: `${process.env.SITE_URL} Contact Form Referral Submission`,
  });

export const contactFormSuccessEvent = () =>
  trackCustomEvent({
    category: "Contact Form",
    action: "Form Submit Success",
    label: `${process.env.SITE_URL} Contact Form Success`,
  });

export const contactFormErrorEvent = () =>
  trackCustomEvent({
    category: "Contact Form",
    action: "Form Submit Error",
    label: `${process.env.SITE_URL} Contact Form Error`,
  });

export const copiedReferralEvent = () =>
  trackCustomEvent({
    category: "Referral Link",
    action: "Copied Referral Link",
    label: `${process.env.SITE_URL} Copied Referral Link`,
  });

export const sharedReferralEvent = ({ platform }) =>
  trackCustomEvent({
    category: "Referral Link",
    action: "Social Referral Link",
    label: `${process.env.SITE_URL} Shared Referral Link - ${platform}`,
  });

export const scrollEvent = ({ percentage }) =>
  trackCustomEvent({
    category: "Page Interaction",
    action: `Scrolled Page - ${percentage}`,
    label: `${process.env.SITE_URL} Scrolled Page`,
  });

export const videoActionEvent = () =>
  trackCustomEvent({
    category: "Video",
    action: "Play Video Click",
    label: `${process.env.SITE_URL} Play Video Click`,
  });

export const videoPlayEvent = () =>
  trackCustomEvent({
    category: "Video",
    action: "Play Video Embed",
    label: `${process.env.SITE_URL} Video Embed Played`,
  });

export const videoWatchedAction = ({ duration, video }) =>
  trackCustomEvent({
    category: "Video",
    action: `Video Watched - ${duration}`,
    label: `${process.env.SITE_URL} Video - ${video}`,
  });

export const videoEmbed = () =>
  trackCustomEvent({
    category: "Page Design",
    action: "Video Embedded",
    label: `${process.env.SITE_URL} Video Embedded`,
  });
