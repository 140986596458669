import React, { useContext } from "react";
import { Formik } from "formik";
import styled from "styled-components";
import {
  contactFormSubmissionEvent,
  contactFormSubmissionReferralEvent,
  contactFormSuccessEvent,
  contactFormErrorEvent,
} from "../helpers/trackers";
import { SIGN_UP_FORM_INITIAL_STATE, SIGN_UP_FORM_NAME } from "../constants";
import {
  validIncomingReferral,
  encode,
  queryHandler,
} from "../helpers/functions";
import { theme } from "../constants/theming";
import ReferralContext, { ReferralUUID } from "../context";

const ReferralContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ThanksText = styled.div`
  font-size: 16px;
  line-height: 1.2;
  color: ${theme.colors.lightGray};
  font-weight: 700;
`;

const SignUpForm = ({ formPosition, children, location }) => {
  const { showReferral, setShowReferral } = useContext(ReferralContext);
  const { uuid } = useContext(ReferralUUID);
  const incomingReferral = (location && queryHandler({ location }).ref) || "";

  const formSubmit = async ({
    email,
    referral,
    incomingReferral,
    "form-name": formName,
    formPosition,
  }) => {
    contactFormSubmissionEvent({ formPosition });

    if (validIncomingReferral({ incomingReferral })) {
      contactFormSubmissionReferralEvent();
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        data: {
          "form-name": formName,
          email,
          referral,
          incomingReferral,
        },
      }),
    })
      .then(() => {
        contactFormSuccessEvent();
        setShowReferral(true);
      })
      .catch(() => contactFormErrorEvent());
  };

  const initialStateWithUniqueId = SIGN_UP_FORM_INITIAL_STATE({ id: uuid });

  return (
    <>
      {!showReferral ? (
        <Formik
          initialValues={{
            ...initialStateWithUniqueId,
            incomingReferral,
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            formSubmit({ formPosition, ...values });
            setSubmitting(false);
          }}
        >
          {({ isSubmitting }) =>
            children({ isSubmitting, formName: SIGN_UP_FORM_NAME })
          }
        </Formik>
      ) : (
        <ReferralContainer>
          <ThanksText>Thanks for signing up!</ThanksText>
        </ReferralContainer>
      )}
    </>
  );
};

export default SignUpForm;
