import React from "react";
import styled from "styled-components";
import { theme } from "../../constants/theming";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  background: ${theme.colors.black};
`;

const PageContainer = ({ children }) => <Container>{children}</Container>;

export default PageContainer;
